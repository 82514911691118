import React, { useEffect, useState } from 'react'
import { useStore } from '../store/Store'
import {BsHeartbreakFill, BsFillChatFill, BsFillHeartFill} from 'react-icons/bs'
import avatar from '../images/avatar.png'
import LikeList from './LikeList'
import i18n from '../locale/i18next';
import { useCookies } from 'react-cookie'
import brand from '../images/brand_4.gif'
import heart from '../images/brand.gif'
import { useTranslation } from 'react-i18next'
import ChatList from './ChatList'
import DarkList from './DarkList'
import { Link, useHref, useLocation, useNavigate } from 'react-router-dom'
import { MdHome, MdQuestionMark } from 'react-icons/md'
import { getChatUsers, getLikeUsers, getdark_listUsers } from '../host/requests/User'
import { noAvatar } from './Checkers'
import useWebSocket from 'react-use-websocket'
import { FaLock } from 'react-icons/fa6'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
export default function Menu() {
  const {t} =useTranslation()
    const setisBlue=useStore(state=>state.setisBlue)
    const token_access=useStore(state=>state.token_access)
    const setlike_page=useStore(state=>state.setlike_page)
    const setlikes=useStore(state=>state.setlikes)
    const setdark_list_page=useStore(state=>state.setdark_list_page)
    const setdark_lists=useStore(state=>state.setdark_lists)
    const setchat_page=useStore(state=>state.setchat_page)
    const setchats=useStore(state=>state.setchats)
    const editchats=useStore(state=>state.editchats)
    const blockchat=useStore(state=>state.blockchat)
    const setlike_next=useStore(state=>state.setlike_next)
    const setdark_list_next=useStore(state=>state.setdark_list_next)
    const setchatsAll=useStore(state=>state.setchatsAll)
    const like_next=useStore(state=>state.like_next)
    const likes=useStore(state=>state.likes)
    const like_page=useStore(state=>state.like_page)
    const dark_list_next=useStore(state=>state.dark_list_next)
    const dark_lists=useStore(state=>state.dark_lists)
    const dark_list_page=useStore(state=>state.dark_list_page)
    const setchat_next=useStore(state=>state.setchat_next)
    const chat_next=useStore(state=>state.chat_next)
    const chats=useStore(state=>state.chats)
    const addlikes=useStore(state=>state.addlikes)
    const chat_count=useStore(state=>state.chat_count)
    const setchat_count=useStore(state=>state.setchat_count)
    const like_count=useStore(state=>state.like_count)
    const setlike_count=useStore(state=>state.setlike_count)
    const chat_page=useStore(state=>state.chat_page)
    const setopen_chat=useStore(state=>state.setopen_chat)
    const editOnlineUser=useStore(state=>state.editOnlineUser)
    
    const user=useStore(state=>state.user)
    const [menu, setMenu]=useState(1)
    const [phoneView, setphoneView]=useState(false)
    const [tool_open, settool_open]=useState(false)
    const [like_spin, setlike_spin]=useState(false)
    const [dark_list_spin, setdark_list_spin]=useState(false)
    const [chat_spin, setchat_spin]=useState(false)
    const [openMenu, setopenMenu]=useState(false)
    const navigate=useNavigate()
    
    const [cookie, setCookie]=useCookies()
    const [til, settil] = useState(cookie.tilTanish?cookie.tilTanish:"uz");
    let location = useLocation();
    const history = useHref();
    const { sendJsonMessage, lastMessage, readyState } = useWebSocket( `wss://api.bez-komplus.com/ws/notifications/`,
    {
         queryParams: {
                token: token_access,
              },
              onOpen: () => {
                console.log("Connected!");
              },
              onClose: (error) => {
              
                console.log("Disconnected!", error);
              },
              onMessage: (e) => {
                const data = JSON.parse(e.data);
                console.log(data)

                switch (data.type) {
                  case "unread_count":
                    console.log("Hello3")
                    setchat_count(data.unread_count)
                    break;
                    case "new_message_notification":
                      editchats(data.conversation, data.conversation_name, 1)
                    break;
                    case "chat_blocked":
                      blockchat(data.conversation)
                    break;
                    case "chat_deleted":
                      var a=chats
                      var b=null
                      a.map((item, key)=>{
                        if(item.name===data.conversation){
                          b=key
                        }
                      })
                      if(b!==null){
                        if(chat_count-a[b].unread_messages>0){
                          console.log("Hello4")
                          setchat_count(chat_count-a[b].unread_messages)
                        }else{
                          setchat_count(0)
                        }
                        
                        a.splice(b, 1)
                        setchatsAll(a)
                        navigate("/")
                      }
                    break;
                    case "user_leave":
                      editOnlineUser(false, data.conversation_name, data.last_login)
                      break;
                      case "liked":
                        addlikes(data)
                        break;
                        case "user_join":
                          editOnlineUser(true, data.conversation_name, data.last_login)
                          break;
                  default:
                    console.error("Unknown message type!");
                    break;
                }
              },
             
    });
    useEffect(()=>{
     if(location.pathname.indexOf('chat')===-1){
      setopen_chat(null)
     }
      if(likes.length===0 && location.pathname.indexOf('login')===-1){
        getLikes()
      }
      if(dark_lists.length===0 && location.pathname.indexOf('login')===-1){
        getdark_lists()
      }
      if(chats.length===0 && location.pathname.indexOf('login')===-1){
        getChats()
      }
    
      if(window.screen.width<=800){
        if(location.pathname.indexOf('chat')!==-1){
          setMenu(2)
        }else if(location.pathname.indexOf('profile')!==-1){
          setMenu(5)
        }else{
          setMenu(4)
          setphoneView(true)
        }
       setopenMenu(false)
       
      }
  
    },[location])

    const getChats=async()=>{
      if(chat_next!==null){
        setchat_spin(true)
        try{
          var res=await getChatUsers({page:chat_page}, token_access)
        
          setchat_next(res.data.next)
          setchat_page(chat_page+1)
          setchats(res.data.results)
          setchat_spin(false)
       }catch(error){
          console.log(error)
          setchat_spin(false)
       }
      }
    }
    
    const getLikes=async()=>{
      if(like_next!==null){
        setlike_spin(true)
        try{
          var res=await getLikeUsers({page:like_page}, token_access)
        
          setlike_next(res.data.next)
          setlike_page(like_page+1)
          setlikes(res.data.results)
          setlike_spin(false)
       }catch(error){
          console.log(error)
          setlike_spin(false)
       }
      }
    }
    const getdark_lists=async()=>{
      if(dark_list_next!==null){
        setdark_list_spin(true)
        try{
          var res=await getdark_listUsers({page:dark_list_page}, token_access)
        
          setdark_list_next(res.data.next)
          setdark_list_page(dark_list_page+1)
          setdark_lists(res.data.results)
          setdark_list_spin(false)
       }catch(error){
          console.log(error)
          setdark_list_spin(false)
       }
      }
    }

    const changeLang=()=>{
        if(til==='uz'){
          i18n.changeLanguage("ru")
          settil("ru")
          setCookie("tilTanish", "ru")
        }else if(til==='ru'){
          i18n.changeLanguage("en")
          settil("en")
          setCookie("tilTanish", "en")
        }else  if(til==='en'){
          i18n.changeLanguage("uz")
          settil("uz")
          setCookie("tilTanish", "uz")
        }
    }
  return (
    <div className='menuDiv'>
      <div className='brand'>
       
        <Link to={'/'} className="brand_name">
         <img src={brand}/>
          <p>Bez-k<img className='heart_gif' src={heart}/>mplus</p>
          <img className="second_img" src={brand}/>
        </Link>
        
        <div className={`changeBox ${tool_open?"openTool":""}`}>
          <div onClick={()=>{settool_open(!tool_open)}} className='tool_open'>
            {tool_open?<IoIosArrowBack />:<IoIosArrowForward />}
          </div>
        <Link to={history.indexOf("profile")!==-1?"/":"/profile"} className='user_box comp'>
          <div className='avatar'>
          <div className='avatar_img' style={{backgroundImage:`url(${user!=null && user.main_image!=null?user.main_image.media:noAvatar(user!==null?user.gender:null)})`}}></div>
          </div>
        </Link>
       
        <Link to="/faq" className='changes changeLang phone'><span className='tool_icon'><MdQuestionMark/></span></Link>

        <div onClick={changeLang} className='changes changeLang'><span>{t("lang")}</span></div>
        <div onClick={setisBlue} className='changes changeColor'></div>
        </div>
        
      </div>
      <div className='menu'>
        <div className='menuHeader'>
        <Link to="/" onClick={()=>{setMenu(4); setopenMenu(false)}} className={`mobileMenu menuHeaderItem ${menu===4?"activeMenu":""}`}>
          <div className='menuIcon'>
              <MdHome/>
            </div>
          </Link>
        <div onClick={()=>{setMenu(1); setlike_count(); if(menu===1){setopenMenu(!openMenu)}else{setopenMenu(true)}}}  className={`menuHeaderItem ${menu===1?"activeMenu":""}`}>
           <div className='menuIcon'>
            <BsFillHeartFill/>
            {like_count!==0?<div className='menuBadge like_count'>
              {like_count}
            </div>:<></>}
            </div>
          </div>
          <div onClick={()=>{setMenu(2); if(menu===2){setopenMenu(!openMenu)}else{setopenMenu(true)}}} className={`menuHeaderItem ${menu===2?"activeMenu":""}`}>
          <div className='menuIcon'>
             <BsFillChatFill/>
             {chat_count!==0?<div className='menuBadge'>
              {chat_count}
            </div>:<></>}
            </div>
          </div>
          
          <div onClick={()=>{setMenu(3); if(menu===3){setopenMenu(!openMenu)}else{setopenMenu(true)}}} className={`menuHeaderItem ${menu===3?"activeMenu":""}`}>
          <div className='menuIcon'>
          <FaLock />
             
            </div>
          </div>
         
          <Link to={"/profile"} onClick={()=>{setMenu(5); setopenMenu(false)}} className={`mobileMenu phone menuHeaderItem ${menu===5?"activeMenu":""}`}>
          <div className='avatar'>
          <div className='avatar_img' style={{backgroundImage:`url(${user!=null && user.main_image!=null?user.main_image.media:noAvatar(user!==null?user.gender:null)})`}}></div>
          </div>
        </Link>
        </div>
        <div className={`menuBody ${phoneView && !openMenu?"closedMenu":""}`}>
        {menu===2?<ChatList  setMenu={setMenu} setopenMenu={setopenMenu} chat_spin={chat_spin} setchat_spin={setchat_spin} getChats={getChats}/>:<></>}
         {menu===1?<LikeList setMenu={setMenu} setopenMenu={setopenMenu} like_spin={like_spin} setlike_spin={setlike_spin} getLikes={getLikes}/>:<></>}
         {menu===3?<DarkList setMenu={setMenu} setopenMenu={setopenMenu} dark_list_spin={dark_list_spin} setdark_list_spin={setdark_list_spin} getdark_lists={getdark_lists}/>:<></>}
         
          {/* <LikeList/> */}
        </div>
      </div>
     
    </div>
  )
}
