import { create } from "zustand";


export const useStore=create((set)=>({
    user:null,
    open_chat:null,
    is_online:false,
    is_typing:false,
    next:null,
    setNext:(val)=>set((state)=>({next:val})),
    setis_typing:(val)=>set((state)=>({is_typing:val})),
    setis_online:(val)=>set((state)=>({is_online:val})),
    setopen_chat:(val)=>set((state)=>({open_chat:val})),
    like_next:"a",
    dark_list_next:"a",
    chat_next:"a",
    setlike_next:(val)=>set((state)=>({like_next:val})),
    setdark_list_next:(val)=>set((state)=>({dark_list_next:val})),
    setchat_count:(val)=>set((state)=>({chat_count:val})),
    setchat_next:(val)=>set((state)=>({chat_next:val})),
    setblockReasons:(val)=>set((state)=>({blockReasons:val})),
    number:0,
    like_page:1,
    dark_list_page:1,
    chat_count:0,
    like_count:0,
    setlike_count:()=>set((state)=>({like_count:0})),

    chat_page:1,
    likes:[],
    dark_lists:[],
    chats:[],
    blockReasons:null,
    random_users:[],
    setrandomusers:(val)=>set(({random_users, number,active_id})=>{
        var a=random_users
        var b=active_id
        if(a.length>=50){
            a=a.slice(25)
            b=b-25
        }
        a=[...a, ...val]
        return({random_users:a, number:number+1, active_id:b})
    }),
    editchats:(val, id, num)=>set(({chats, chat_count})=>{
        var a=chats
        var b=null
        var c=null
        a.map((item, key)=>{
          if(item.name===id){
              b=key
              c=item
  
          }
        })
        if(c!==null){
          if(c.unread_messages+num!==0){
            if(num<0){
                c.unread_messages=0
            }else{
                c.unread_messages=c.unread_messages+num
                a.splice(b, 1)
                a.unshift(c)
            }
             
          }else{
              c.unread_messages=0
          }
        
        }else{
            a.unshift(val)
            a[0].unread_messages=1
            a[0].is_online=true
           }
        
        var g=a.reduce((sum, obj) => sum + obj.unread_messages, 0);
          
          return({chats:a, chat_count:g})
      }),
      delete_dark_list:(val)=>set(({dark_lists})=>{
        var a=dark_lists
        var b=null
        a.map((item, key)=>{
          if(item.id===val){
              b=key
         }
        })
       if(b!==null){
        a.splice(b, 1)
       }
        return({dark_lists:a})
      }),
      delete_chat:(val, reason,id)=>set(({chats, open_chat, dark_lists})=>{
        var a=chats
        var b=null
        a.map((item, key)=>{
          if(item.name===val){
              b=key
         }
        })
        a[b].me_blocked=true
        a[b].block_id=id
        var c=open_chat
        var gj=dark_lists
        if(c!==null && c.name===val){
            c.me_blocked=true
            c.block_id=id
            console.log(reason)
            gj.unshift({created_at: null,
            id:null,
            reason:reason,
            user:open_chat.other_user 
            })
        }
        return({chats:a, open_chat:c, dark_lists:gj})
      }),
      blockchat:(val)=>set(({chats, open_chat, number, is_online, is_typing})=>{
        var a=chats
        var b=null
        a.map((item, key)=>{
          if(item.name===val){
              b=key
         }
        })
        if(b!==null){
            a[b].blocked_me=true
            a[b].is_online=false
            a[b].other_user.is_online=false
            a[b].other_user.main_image=null
            a[b].other_user.last_login="0001-01-01T00:00:00Z"
        }
        var c=open_chat
        console.log(c)
        var t=is_online
        var j=is_online
        if(c!==null && c.name===val){
            t=false
            j=false
            c.blocked_me=true
            c.is_online=false
            c.other_user.is_online=false
            c.other_user.main_image=null
            c.other_user.last_login="0001-01-01T00:00:00Z"
        }
        console.log(c)
        return({chats:a, open_chat:c, number:number+1, is_online:t, is_typing:j})
      }),
      editOnlineUser:(val, id, last_login)=>set(({chats})=>{
        var a=chats
        var b=null
        
        a.map((item, key)=>{
          if(item.name===id){
              b=key
          }
        })
        if(b!==null){
        a[b].last_login=last_login
        a[b].is_online=val 
        if(a[b].blocked_me){
            a[b].is_online=false
        }
        }
       
        return({chats:a})
      }),
    addusersrandom:(val)=>set(({random_users, number,active_id})=>{
        var a=random_users
        console.log(a)

        var b=a.slice(0, active_id)
        var c=a.slice(active_id)
        var ac_id=active_id
        a=[...b, val, ...c]
        var ids=[]
        a.map((item, key)=>{
            if(item.id===val.id && key!==ac_id){
                ids.push(key)
            }
        })
        ids.map((item)=>{
            a.splice(item, 1)
            if(item<ac_id){
                ac_id--
            }
        })
        console.log(b, c, a)
        return({random_users:a, number:number+1})
    }),
    setlikes:(val)=>set(({likes, number})=>{
        var a=likes
        a=[...a, ...val]
        return({likes:a, number:number+1})
    }),
    addlikes:(val)=>set(({likes, number, like_count})=>{
        var a=likes
        a=[val, ...a]
        return({likes:a, number:number+1, like_count:like_count+1})
    }),
    setdark_lists:(val)=>set(({dark_lists, number})=>{
        var a=dark_lists
        a=[...a, ...val]
        return({dark_lists:a, number:number+1})
    }),
    setchats:(val)=>set(({chats, number})=>{
        var a=chats
        a=[...a, ...val]
        return({chats:a, number:number+1})
    }),
    setchatsAll:(val)=>set(({chats, number})=>{
       return({chats:val, number:number+1})
    }),
    setchatsfirst:(val)=>set(({chats, number})=>{
        var a=chats
        var b=null
        a.map((item, key)=>{
            if(item.id===val.id){
                b=key
            }
        })
        if(b!==null){
            a.splice(b, 1)
        }
        a=[val, ...a]
        return({chats:a, number:number+1})
    }),
    setrandomlike:(val)=>set(({random_users, number})=>{
        var a=random_users
        a[val].likes=a[val].likes+1
        a[val].reactions=[...a[val].reactions, {reaction:'liked'}]
        return({random_users:a, number:number+1})
    }),
    
    filter:null,
    loader:false,
    page_size:1,
    active_id:null,
    countries:null,
    setactive_id:(val)=>set((state)=>({active_id:val})),
    setlike_page:(val)=>set((state)=>({like_page:val})),
    setdark_list_page:(val)=>set((state)=>({dark_list_page:val})),
    setchat_page:(val)=>set((state)=>({chat_page:val})),
    setcountries:(val)=>set((state)=>({countries:val})),
    setpage_size:(val)=>set((state)=>({page_size:val})),
    hobbies:null,
    sethobbies:(val)=>set((state)=>({hobbies:val})),
    states:null,
    setstates:(val)=>set((state)=>({states:val})),
    token_access:null,
    token_refresh:null,
    isBlue:false,
    setisBlue:(val)=>set((state)=>({isBlue:!state.isBlue})),
    settoken_access:(val)=>set((state)=>({token_access:val})),
    
    setuser:(val)=>set((state)=>({user:val})),
    setfilter:(val)=>set((state)=>({filter:val})),
    setloader:(val)=>set((state)=>({loader:val})),
    settoken_refresh:(val)=>set((state)=>({token_refresh:val})),
    changeLastLogin:(val)=>{set(({chats, open_chat})=>{
          var a=chats
          var b=null
          a.forEach((item, key)=>{
            if(item.other_user.id===val){
               b=key
            }
          })
          if(b!==null){
            a[b].other_user.last_login=Date()
          }
          return({chats:a})
    })}
}))