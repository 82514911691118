import React, { useState } from 'react'
import logo from '../images/landing_page/logo_2.png'
import stat_1 from '../images/landing_page/stat_1.png'
import stat_2 from '../images/landing_page/stat_2.png'
import stat_3 from '../images/landing_page/stat_3.png'
import stat_4 from '../images/landing_page/stat_4.png'
import male_icon from '../images/landing_page/male_icon.png'
import female_icon from '../images/landing_page/famale_icon.png'
import { GiCottonFlower } from "react-icons/gi";
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import i18n from '../locale/i18next';
import { IoMdLogIn } from "react-icons/io";
import '../css/LandingPage.css'
import { Link } from 'react-router-dom';
import { FaBell, FaGrinStars, FaHeart, FaStarAndCrescent } from 'react-icons/fa';
import { BsChatFill } from 'react-icons/bs';
import { AiFillDislike, AiFillLike } from 'react-icons/ai';

export default function Dashboard() {
    const [cookie, setCookie]=useCookies()
    const [til, settil] = useState(cookie.tilTanish?cookie.tilTanish:"uz");
    const {t} =useTranslation()
    const changeLang=()=>{
        if(til==='uz'){
          i18n.changeLanguage("ru")
          settil("ru")
          setCookie("tilTanish", "ru")
        }else if(til==='ru'){
          i18n.changeLanguage("en")
          settil("en")
          setCookie("tilTanish", "en")
        }else  if(til==='en'){
          i18n.changeLanguage("uz")
          settil("uz")
          setCookie("tilTanish", "uz")
        }
    }
  return (
    <div className='landing_page'>
       <div className='header'>
        <div className='brand'>
            <img src={logo}/>
        </div>
        <div className='header_list'>
         <div onClick={changeLang} className='changes changeLang'><span>{t("lang")}</span></div>
        </div>
        <Link to={"/login"} className='phone_login_btn'>
        <p>{t("login")}</p>
        <span><IoMdLogIn /></span>
        </Link>
       </div>
       <div className='phone_main'>
        
           <div className='phone_mobile'>
           <Link className='mobile_link' to={"login"}>
                <span><img src={female_icon} /></span>
                <p>{t("i_female")}</p>
              </Link>
              <Link className='mobile_link' to={"login"}>
                <span><img src={male_icon} /></span>
                <p>{t("i_male")}</p>
              </Link>
           </div>
       </div>
       <div className='comp_main'>
       <div className='main'>
        <div className='main_icons'>
            <div className='icons_box'>
            <div className='icon icon_red'><AiFillLike /></div>
            <div className='icon icon_white'><BsChatFill /></div>
            <div className='icon icon_red'><FaHeart /></div>
            <div className='icon icon_white'><AiFillDislike /></div>
            </div>
       
        </div>
        <div className='main_text'>
        <span>{t("Siz ko'prog'iga loyiqsiz")}</span>
        <h1 dangerouslySetInnerHTML={{__html:t("O'z juftingizni <span>Tanishuv Platformasi</span> orqali toping")}} />
        <p>{t("Bu platforma sizga ko'plab insonlar bilan muloqotda bo'lish, yangi do'stlar orttirish va o'z juftingizni topishingizga yordam beradi. Hoziroq ro'yhatdan o'ting va ko'plab imkoniyatlarga ega bo'ling.")}</p>
       <Link to={"/login"} className="landing_btn">{t("Tizimga kirish")}</Link>
       </div>
       </div>
       <div className='statistic'>
       <div className='main_icons'>
            <div className='icons_box'>
            <div className='icon icon_red'><FaGrinStars /></div>
            <div className='icon icon_white'><GiCottonFlower /></div>
            <div className='icon icon_red'><FaHeart /></div>
            <div className='icon icon_white'><FaBell /></div>
            </div>
               
        </div>
        <div className='stat_box'>
       <div className='stat_item'>
            <img src={stat_1}/>
            <h1>12576</h1>
            <p>{t("Foydalanuvchi")}</p>
         </div>
         <div className='stat_item'>
            <img src={stat_2}/>
            <h1>6112</h1>
            <p>{t("Online")}</p>
         </div>
         <div className='stat_item'>
            <img src={stat_3}/>
            <h1>5234</h1>
            <p>{t("Erkak")}</p>
         </div>
         <div className='stat_item'>
            <img src={stat_4}/>
            <h1>7342</h1>
            <p>{t("Ayol")}</p>
         </div>
         </div>
       </div>
       <div className='main about'>
        <div className='main_icons'>
            <div className='icons_box'>
            <div className='icon icon_red'><AiFillLike /></div>
            <div className='icon icon_white'><BsChatFill /></div>
            <div className='icon icon_red'><FaHeart /></div>
            <div className='icon icon_white'><AiFillDislike /></div>
            </div>
       
        </div>
        <div className='main_text'>
        <span>{t("")}</span>
        <h1 dangerouslySetInnerHTML={{__html:t("Yangi <span>Tanishuvlar</span>  <br/> Yangi <span>Do'stlar</span> <br/> Yangi <span>Hayot</span>")}} />
        <p>{t("Atrofingizdagi insonlar bilan qarashlaringiz bir biriga mos kelmayaptimi? Unda platformada ro'yhatdan o'ting va o'zingizga mos insonlar bilan tanishing. Hayotingizni o'zgartirish vaqti keldi!")}</p>
       <Link to={"/login"} className="landing_btn">{t("Tizimga kirish")}</Link>
       </div>
       </div>
       <div className='footer'>
       <div className='brand'>
            <img src={logo}/>
        </div>
        <div className='created'>
          <a target='_blank' href="https://github.com/Rahmonbek">{t("© 2023 IT WEB tomonidan tayyorlandi")}</a>
          </div> 
        </div> 

       </div>
           </div>
  )
}
