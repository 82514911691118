import React, { useState } from 'react'
import { useStore } from '../store/Store'
import { checkDate, noAvatar } from './Checkers'
import { useTranslation } from 'react-i18next'
import { GoPlus } from "react-icons/go";
import { AiOutlineLoading } from "react-icons/ai";
import { deleteUserBlock, getUserById } from '../host/requests/User';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { IoIosWarning } from 'react-icons/io';
import { dangerAlert, successAlert } from './Alert';
import { PuffLoader } from 'react-spinners';
export default function DarkList({getdark_lists, dark_list_spin, setdark_list_spin, setopenMenu, setMenu}) {
  const dark_lists=useStore(state=>state.dark_lists)
  const token_access=useStore(state=>state.token_access)
  const [modal, contextHolder] = Modal.useModal();
  const dark_list_next=useStore(state=>state.dark_list_next)
  const setloader=useStore(state=>state.setloader)
  const addusersrandom=useStore(state=>state.addusersrandom)
  const delete_dark_list=useStore(state=>state.delete_dark_list)
  const navigate=useNavigate()
  const {t} =useTranslation()

  const getUserOne=async(id)=>{
    try{
      setloader(true)
      var res=await getUserById({pk:id}, token_access)
       addusersrandom(res.data)
       setloader(false)
    }catch(error){
      console.log(error)
      setloader(false)
    }
  }
  const openModalConfirm=(id)=>{
    console.log(id)
    modal.confirm({
        title: t("info"),
        icon: <span className='warning_icon'><IoIosWarning /></span>,
        content: t("info_block"),
        okText: t('Ha'),
        cancelText: t("Yo'q"),
        onOk:()=>{deleteBlock(id)},
       
      });
}
const deleteBlock=async(id)=>{
  setloader(true)
      try{
        var res=await deleteUserBlock(id, token_access)
        successAlert(t("block_open"))
        delete_dark_list(id)
        setloader(false)
        }catch(error){
        dangerAlert(t("block_unopen"))
        setloader(false)
      }
}
  return (
    <>
     {contextHolder}
    
    <div className='menuList'>
      {dark_lists.length===0? <div className='listBox'>
        <div className='emptyList'></div>
    </div>:dark_lists.map((item, key)=>{
      return(<div onClick={()=>{openModalConfirm(item.id);}} key={key} className='menuListItem'>
      <div className='user_box'>
       <div className='avatar'>
       <div className='avatar_img' style={{backgroundImage:`url(${item.user!==null && item.user.main_image!==null?item.user.main_image:noAvatar(item!==null?item.user.gender:null)})`}}></div>
         
       </div>
       <div className='username'>
            <h1>{item.user!==null?item.user.first_name:""}</h1>
            <p>{item.reason!==null?item.reason[`reason_${t("error_api")}`]:t("reason")}</p>
       </div>
     </div>
     
     </div> )
    })}
            
      {dark_list_next!==null && dark_list_next!=="a"?
       <div onClick={()=>{getdark_lists()}} className='addBtnBox'>
       {dark_list_spin?<div className='loadBtn'><PuffLoader /></div>: <button><GoPlus/></button>}
      
     </div>
      :<></>}
      
        </div>
        </>
  )
}
