import React, { useState } from 'react'
import { useStore } from '../store/Store'
import { checkDate, formatDateList, noAvatar } from './Checkers'
import { useTranslation } from 'react-i18next'
import { GoPlus } from "react-icons/go";
import { AiOutlineLoading } from "react-icons/ai";
import { getUserById } from '../host/requests/User';
import { useNavigate } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
export default function LikeList({getLikes, like_spin, setlike_spin, setopenMenu, setMenu}) {
  const likes=useStore(state=>state.likes)
  const token_access=useStore(state=>state.token_access)
  const like_next=useStore(state=>state.like_next)
  const setloader=useStore(state=>state.setloader)
  const addusersrandom=useStore(state=>state.addusersrandom)
  const navigate=useNavigate()
  const {t} =useTranslation()

  const getUserOne=async(id)=>{
    try{
      setloader(true)
      var res=await getUserById({pk:id}, token_access)
       addusersrandom(res.data)
       setloader(false)
    }catch(error){
      console.log(error)
      setloader(false)
    }
  }
  return (
    <div className='menuList'>
      {likes.length===0? <div className='listBox'>
        <div className='emptyList'></div>
    </div>:likes.map((item, key)=>{
      return(<div onClick={()=>{getUserOne(item.user.id); if(window.screen.availWidth<=800){setMenu(4);} setopenMenu(false); navigate("/")}} key={key} className='menuListItem'>
      <div className='user_box'>
       <div className='avatar'>
       <div className='avatar_img' style={{backgroundImage:`url(${item.user!==null && item.user.main_image!==null?item.user.main_image:noAvatar(item!==null?item.user.gender:null)})`}}></div>
         
       </div>
       <div className='username'>
            <h1>{item.user!==null?item.user.first_name:""}</h1>
            <p>{formatDateList(item.created_at, t("error_api"))}</p>
       </div>
     </div>
     
     </div> )
    })}
            
      {like_next!==null && like_next!=="a"?
       <div onClick={()=>{getLikes()}} className='addBtnBox'>
       {like_spin?<div className='loadBtn'><PuffLoader /></div>: <button><GoPlus/></button>}
      </div>
      :<></>}
   
        </div>
  )
}
