import { Button, Form, Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { dangerAlert, successAlert } from '../components/Alert'
import { login } from '../host/requests/Auth'
import { useStore } from '../store/Store'
import { getUserFilter, getUserProfile } from '../host/requests/User'
import { checkUsersData } from '../components/Checkers'
import { CgGenderFemale, CgGenderMale } from "react-icons/cg";
import { FaHandHoldingHeart } from 'react-icons/fa'
import { FaHeart, FaHeartPulse, FaMessage } from "react-icons/fa6";
import { GiLoveSong } from 'react-icons/gi'
import { people_data } from '../components/Data'
import { PeopleCards } from '../components/PeopleCards'
export default function Login() {
  const settoken_access=useStore(state=>state.settoken_access)
    const settoken_refresh=useStore(state=>state.settoken_refresh)
    const setuser=useStore(state=>state.setuser)
    const setfilter=useStore(state=>state.setfilter)
    const setloader=useStore(state=>state.setloader)
    const {t}=useTranslation()
    const navigate=useNavigate()
    const  onFinish=async(val)=>{
      setloader(true)
      try{
        var res=await login(val)
        settoken_access(res.data.access)
      settoken_refresh(res.data.refresh)
         try{
          var res1=await getUserProfile(null, res.data.access)
          var res2=await getUserFilter(null, res.data.access)
          if(!checkUsersData(res1.data, res2.data)){
            successAlert(t("fill_user_data"))
          }
          
          setuser(res1.data)
          setfilter(res2.data)
          setloader(false)
          }catch (error){
            setloader(false)
          dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")
         }
         navigate('/')
       }catch (error){
        setloader(false)
        if(!!error.response.data.detail){
          if(error.response.data.detail==="no_user_exists"){
            navigate("/register")
          }
          dangerAlert(t(error.response.data.detail))
        }else{
          dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")}
      }
    }
  return (
    <div className='login_big_box'>
        <div className='form_box'>
        <div className='red_box_form'></div>
            <div className='blue_box_form'></div>
            
        <div className='login_box'>
            
            <h1>{t("login")}</h1>
        <Form
    name="basic"
    labelCol={{
      span: 24,
    }}
    wrapperCol={{
      span: 24,
    }}
   initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    // onFinishFailed={onFinishFailed}
    
  >
    <Form.Item
      label={t("email")}
      name="email"
      rules={[
        {
          type: 'email',
          message: "",
        },
        {
           required: true,
           message: "",
            
        }
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label={t("password")}
      name="password"
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Input.Password  autoComplete="new-password"/>
    </Form.Item>
     <Form.Item
      wrapperCol={{
        span: 24,
      }}
    >
      <Button className='login_btn' type="primary" htmlType="submit">
        {t("login")}
      </Button>
    </Form.Item>
  </Form>
  <div className="social">
          <Link to="/register" className='btns_form'>{t("registration")}</Link>
          <Link to="/change-password" className='btns_form'>{t("change-password")}</Link>
        </div>
        </div>
        </div>
        <div className='phone'>
          <div className='phone_back_login'>
          <span><FaHeartPulse /></span>
          <span><CgGenderMale /></span>
          <span><CgGenderFemale /></span>
          <span><FaHandHoldingHeart /></span>
          <span><GiLoveSong /></span>
          <span><FaHeartPulse /></span>
          <span><CgGenderMale /></span>
          <span><CgGenderFemale /></span>
          <span><FaHandHoldingHeart /></span>
          <span><GiLoveSong /></span>
          <span><FaHeartPulse /></span>
          <span><CgGenderMale /></span>
          <span><CgGenderFemale /></span>
          <span><FaHandHoldingHeart /></span>
          <span><GiLoveSong /></span>
          </div>
       <PeopleCards/>
        </div>
        
    </div>
  )
}
