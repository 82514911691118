import React, { useEffect } from 'react'
import Menu from '../components/Menu'
import Header from '../components/Header'
import { useTranslation } from 'react-i18next'
import Profiles from './Profiles'
import { Link, Route, Routes } from 'react-router-dom'
import SelfProfile from './SelfProfile'
import {MdHome, MdQuestionMark} from 'react-icons/md'
import { useHref } from 'react-router-dom';
import Faq from './Faq'
import FaqItem from './FaqItem'
import { useStore } from '../store/Store'
import { checkUsersData } from '../components/Checkers'
import Chat from './Chat'


export default function Home() {
   const {t} =useTranslation()
   const history = useHref();
   const user=useStore(state=>state.user)
   useEffect(()=>{
    console.log(checkUsersData(user), user)
   }, [])

  return (
    <div className='bigBox'>
        <div className='bigMenu'>
            <Menu/>
        </div>
        <div className='bigContent'>
          {!checkUsersData(user)?<SelfProfile/>:
          <Routes> <Route path='' element={<Profiles/>} />
            <Route path='profile' element={<SelfProfile/>} />
            <Route path='faq' element={<Faq/>} />
            <Route path='faq/:id' element={<FaqItem/>} />
            <Route path="chat/:chat_id" element={<Chat/>}/>
            </Routes>}
          
          {/* {history!=='/'?<Link className='goHome' to="/"><MdHome/></Link>:<></>} */}
          <Link className='goHome faq' to="/faq"><MdQuestionMark/></Link>
        </div>
    </div>
  )
}
