import { FaMessage } from "react-icons/fa6"
import { people_data } from "./Data"
import { FaHeart } from "react-icons/fa"

export const PeopleCards=()=>{
    return(
        <div className='people_data'>
        {people_data.map((item, key)=>{
          return(
            <div  className='people_card'>
              <div className='people_img'>
                <img src={item.img}/>
              </div>
              <div className='people_text'>
              
              <div className='people_btns'>
                <a href="#"><FaMessage /></a>
                <p>{item.name}</p>
                <a href="#" className='heart_icon_btn'><FaHeart /></a>
              </div>
              </div>
            </div>
          )
        })}
       </div>
    )
}