import React, { useState } from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Login from './Login'
import Registration from './Registration'
import ChangePassword from './ChangePassword'
import Dashboard from './Dashboard'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import i18n from '../locale/i18next'
import brand from '../images/brand_4.gif'
import heart from '../images/brand.gif'

export default function LoginBox() {
 
  return (
    <div>
      
        <Routes>
        <Route path='' element={<Dashboard/>} />
        {/* <Route path='' element={<Login/>} /> */}
        <Route path='login' element={
          <>
        <Link to={'/'} className="brand_name phone_login_header">
         <img src={brand}/>
          <p>Bez-k<img className='heart_gif' src={heart}/>mplus</p>
          <img  className="second_img" src={brand}/>
        </Link>
        <Login/>
        </>
        } />
        <Route path='register' element={
           <>
           <Link to={'/'} className="brand_name phone_login_header">
         <img src={brand}/>
          <p>Bez-k<img className='heart_gif' src={heart}/>mplus</p>
          <img  className="second_img" src={brand}/>
        </Link>
       <Registration/>
       </>
        } />
        <Route path='change-password' element={
        <>
        <Link to={'/'} className="brand_name phone_login_header">
         <img src={brand}/>
          <p>Bez-k<img className='heart_gif' src={heart}/>mplus</p>
          <img  className="second_img" src={brand}/>
        </Link>
       <ChangePassword/>
       </>
       } />
        </Routes>
    </div>
  )
}
