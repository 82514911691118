import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHref, useNavigate } from 'react-router-dom'
import { changePasswordEmail, createPassword, registerEmail, resetPassword, sendEmailCodeHttp } from '../host/requests/Auth'
import { checkResponse } from '../host/Host'
import { dangerAlert, successAlert } from '../components/Alert'
import { useStore } from '../store/Store'
import { getUserProfile } from '../host/requests/User'
import { PeopleCards } from '../components/PeopleCards'

export default function ChangePassword() {
  const [form] = Form.useForm();
  
    const [type, setType]=useState(1)
    const [time, settime]=useState(250)
    const [email, setemail]=useState(null)
    const [created, setcreated]=useState(false)
    const token_access=useStore(state=>state.token_access)
    const setloader=useStore(state=>state.setloader)
    const {t}=useTranslation()
    const navigate = useNavigate();
   const emailForm=form;
   const codeForm=form;
    const  onFinish=async(val)=>{
      val.email=email
      setloader(true)
     try{
        var res=await resetPassword(val, token_access)
        settime(0)
        codeForm.resetFields()
        setemail(null)
        successAlert(t("password_changed"))
        setcreated(true)
        setloader(false)
      }catch (error){
        console.log(error, !!error.response.data.detail)
        if(!!error.response.data.detail){
          dangerAlert(t(error.response.data.detail))
        }else{
          dangerAlert(t("error_password"))
        }
        setloader(false)
      }
    }

    const getEmailCode=async(val)=>{
      setloader(true)
      console.log(val)
      setemail(val.email)
      try{
        var res=await changePasswordEmail(val)

        successAlert(t("send_code_email"))
        emailForm.resetFields()
        
        setType(2)
        startTime()
        setloader(false)
      }catch (error){
        if(error.response.data.detail){
          dangerAlert(t(error.response.data.detail))
        }else{
          dangerAlert(t("send_code_email_error"))

        }
        setloader(false)
      }
   }

 const startTime=()=>{
  var b=setInterval(()=>{
    var history=document.querySelector(".history").innerHTML
    if(history.indexOf("change-password")===-1){
      clearInterval(b)
    }else{
      if(Number(document.querySelector(".email_time_time").innerHTML)-1<=0){
        clearInterval(b)
        if(document.querySelector(".created").innerHTML==='true'){
         navigate('/login')
        }
        if(Number(document.querySelector(".type").innerHTML)===2){
         goEmail()
        }
       }else{
         settime(Number(document.querySelector(".email_time_time").innerHTML)-1)
       }
    }
   
    }, 1000)
 }

 const goEmail=()=>{
  setType(1)
  setemail(null)
  settime(250)
 }
  return (
    <div className='login_big_box'>
        <div className='form_box'>
        <div className='red_box_form'></div>
            <div className='blue_box_form'></div>
           {type===1?
             <div className='login_box'>
            
             <h1>{t("change-password")}</h1>
         <Form
     form={emailForm}
     name="emailForm"
     labelCol={{
       span: 24,
     }}
     wrapperCol={{
       span: 24,
     }}
    initialValues={{
       remember: true,
     }}
     onFinish={getEmailCode}
     
     // onFinishFailed={onFinishFailed}
     autoComplete="new-password"
   >
     <Form.Item
       label={t("email")}
       name="email"
       rules={[
         {
           type: 'email',
           message: "",
         },
         {
            required: true,
            message: "",
             
         }
       ]}
     >
       <Input   autoComplete="new-password"/>
     </Form.Item>
     <Form.Item
       wrapperCol={{
         span: 24,
       }}
     >
       <Button className='login_btn' type="primary" htmlType="submit">
         {t("send")}
       </Button>
     </Form.Item>
   </Form>
   <div className="social">
           <Link to="/login" className='btns_form'>{t("login")}</Link>
           <Link to="/register" className='btns_form'>{t("registration")}</Link>
         </div>
         </div>
           :type===2?
           <div className='login_box'>
            
           <h1>{t("registration")}</h1>
       <Form
   name="basic1"
   labelCol={{
     span: 24,
   }}
   wrapperCol={{
     span: 24,
   }}
  initialValues={{
     remember: true,
   }}
   onFinish={onFinish}
   form={codeForm}
   // onFinishFailed={onFinishFailed}
   autoComplete="new-password"
 >
  <div className='email_code_div'>
   <Form.Item
      label={t("password_email")}
      name="code"
      rules={[
        {
          len:6,
          message: t("code_email_len"),
        },
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Input  autoComplete="new-password"/>
    </Form.Item>
    <div className={`email_time ${time<=10?"red_time":""}`}>
      <span>{parseInt(time/60)<10?"0":""}{parseInt(time/60)}</span>
      <span>:</span>
      <span>{time%60<10?"0":""}{time%60}</span>

    </div>
    </div>
    <Form.Item
      label={t("password")}
      name="password"
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Input.Password   autoComplete="new-password"/>
    </Form.Item>
    <Form.Item
      label={t("check_password")}
      name="confirm_password"
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Input.Password onPaste={(e) => {e.preventDefault()}}  autoComplete="new-password"/>
    </Form.Item>
   <Form.Item
     wrapperCol={{
       span: 24,
     }}
   >
     <Button className='login_btn' type="primary" htmlType="submit">
       {t("send")}
     </Button>
   </Form.Item>
 </Form>

       </div>:<></>
        } 
      
        </div>
        <PeopleCards/>
        <div className='email_time_time' style={{display:'none'}}>{time}</div>
        <div className='type' style={{display:'none'}}>{type}</div>
        <div className='created' style={{display:'none'}}>{`${created}`}</div>
    </div>
  )
}
