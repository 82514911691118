import img_1 from '../images/landing_page/people/1.jpg'
import img_2 from '../images/landing_page/people/2.jpg'
import img_3 from '../images/landing_page/people/3.jpg'
import img_4 from '../images/landing_page/people/4.jpg'
import img_5 from '../images/landing_page/people/5.jpg'
import img_6 from '../images/landing_page/people/6.jpg'
import img_7 from '../images/landing_page/people/7.jpg'
import img_8 from '../images/landing_page/people/8.jpg'
import img_9 from '../images/landing_page/people/9.jpg'
import img_10 from '../images/landing_page/people/10.jpg'
import img_11 from '../images/landing_page/people/11.jpg'
import img_12 from '../images/landing_page/people/12.jpg'
import img_13 from '../images/landing_page/people/13.jpg'
import img_14 from '../images/landing_page/people/14.jpg'
import img_15 from '../images/landing_page/people/15.jpg'
import img_16 from '../images/landing_page/people/16.jpg'
import img_17 from '../images/landing_page/people/17.jpg'
import img_18 from '../images/landing_page/people/18.jpg'
import img_19 from '../images/landing_page/people/19.jpg'
import img_20 from '../images/landing_page/people/20.jpg'
import img_21 from '../images/landing_page/people/21.jpg'
import img_22 from '../images/landing_page/people/22.jpg'
import img_23 from '../images/landing_page/people/23.jpg'
import img_24 from '../images/landing_page/people/24.jpg'

export const people_data=[
    {
        img:img_23,
        name:'Диана, 24'
    },
    {
        img:img_12,
        name:'Полина, 23'
    },
    {
        img:img_1,
        name:'Анна, 28'
    },
    {
        img:img_2,
        name:'Наталья, 21'
    },
    {
        img:img_3,
        name:'Алексей, 24'
    },
    {
        img:img_4,
        name:'Михаил, 26'
    },
    {
        img:img_5,
        name:'Ольга, 24'
    },
    {
        img:img_6,
        name:'София, 25'
    },
    {
        img:img_7,
        name:'Николай, 29'
    },
    {
        img:img_8,
        name:'Вика, 30'
    },
    {
        img:img_9,
        name:'Ирина, 22'
    },
    {
        img:img_10,
        name:'Мария, 21'
    },
    {
        img:img_11,
        name:'Елена, 19'
    },
   
    {
        img:img_13,
        name:'Ксения, 24'
    },
    {
        img:img_14,
        name:'Владимир, 24'
    },
    {
        img:img_15,
        name:'Павел, 27'
    },
    {
        img:img_16,
        name:'Юлия, 22'
    },
    {
        img:img_17,
        name:'Вера, 19'
    },
    {
        img:img_18,
        name:'Роман, 22'
    },
    {
        img:img_19,
        name:'Зоя, 21'
    },
    {
        img:img_20,
        name:'Юрий, 24'
    },
    {
        img:img_21,
        name:'Мила, 22'
    },
    {
        img:img_22,
        name:'Игорь 23'
    },
   
    {
        img:img_24,
        name:'Наталья, 19'
    },
    
]