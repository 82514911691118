import { Carousel } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import {IoSearch} from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import ProfileCards from './ProfileCards'
import {FaArrowRight, FaArrowLeft} from 'react-icons/fa6'
import {MdOutlineSubdirectoryArrowLeft, MdOutlineSpaceBar} from 'react-icons/md'
import ProfileSearch from './ProfileSearch'
import { getUsersDataRandomSearch } from '../host/requests/User'
import { useStore } from '../store/Store'
export default function Profiles() {
   const [text, setText]=useState("")
   const [loader, setloader]=useState(false)
   const [data, setdata]=useState([])
  const token_access=useStore(state=>state.token_access)

    const {t}=useTranslation()
   useEffect(()=>{
    if(text.length!==0){
        getDataSearch()
    }else{
      setdata([])
    }
   },[text])
   
   const getDataSearch=async()=>{
    try{
      setdata([])
      setloader(true)   
   var res=await getUsersDataRandomSearch({full_name:text}, token_access)
  setdata(res.data.results)
  console.log(res.data.results)
   setloader(false)
  }catch(error){
   setloader(false)
   console.log(error)
  }
   }
  return (
    <div className='profile'>
        <div className='search'>
        <input value={text} onChange={(e)=>{setText(e.target.value)}}  type='text' placeholder={t("search")}/>
        <div className={`searchBtn ${text.length>0?"active_text":""}`}><IoSearch/></div>
        </div>
        <div>
          {text.length===0?<ProfileCards/>:<ProfileSearch setdata={setdata} loader={loader} setText={setText} data={data}/>}
        </div>
       <div className='keysDiv'>
       <div className='keysItem'>
         <div>   <FaArrowRight/></div>
          <p>{t("press_next")}</p>
        </div>
        <div className='keysItem'>
        <div>  <FaArrowLeft/></div>
          <p>{t("press_prev")}</p>
        </div>
        <div className='keysItem'>
        <div>  <MdOutlineSubdirectoryArrowLeft/></div>
          <p>{t("press_like")}</p>
        </div>
        <div className='keysItem'>
        <div>  <MdOutlineSpaceBar/></div>
          <p>{t("press_chat")}</p>
        </div>
       </div>
    </div>
  )
}
