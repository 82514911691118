import { Button, Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../store/Store';
import { getCountriesHttp, getHobbiesHttp, getStatesHttp } from '../host/requests/Countries';
import { addImageToUser, deleteUserImage, editUserFilter, editUserProfile, getUserProfile, setUserImageMain } from '../host/requests/User';
import { IoIosAddCircle, IoIosCloseCircle } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { dangerAlert, successAlert } from '../components/Alert';
import {  changeDateFormat, maxDate, minDate, noAvatar } from '../components/Checkers';
import Compressor from 'compressorjs';



export default function SelfProfile() {
    const [form] = Form.useForm();
    const {t}=useTranslation()
    const [hobby, sethobby] = useState([]);
    const [hobby_filter, sethobby_filter] = useState([]);
    const [country_filter, setcountry_filter] = useState([]);
    const [birthdate, setbithdate] = useState("");
    const [gender, setgender] = useState(null);
    const [gender_filter, setgender_filter] = useState(null);
    const [country, setcountry] = useState(null);
    const [region, setregion] = useState(null);
    
    const [formattedminDate, setformattedminDate] = useState('');
    const [formattedmaxDate, setformattedmaxDate] = useState('');
    const [number, setnumber] = useState(0);
    const [minage, setminage] = useState(16);
    const [maxage, setmaxage] = useState(99);
    const token_access=useStore(state=>state.token_access)
    const countries=useStore(state=>state.countries)
    const setcountries=useStore(state=>state.setcountries)
    const hobbies=useStore(state=>state.hobbies)
    const user=useStore(state=>state.user)
    const filter=useStore(state=>state.filter)
    const sethobbies=useStore(state=>state.sethobbies)
    const setuser=useStore(state=>state.setuser)
    const setfilter=useStore(state=>state.setfilter)
    const states=useStore(state=>state.states)
    const setstates=useStore(state=>state.setstates)
    const setloader=useStore(state=>state.setloader)
    const filterOption = (input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    


    const uploadImage=async(e)=>{
      setloader(true)
      var data=new FormData()
      const file=e.target.files[0]
      
      new Compressor(file, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: async (compressedResult) => {
       try{
        data.append("media", compressedResult)
        var res=await addImageToUser(data, token_access)
        getUserProfileFunc()
      }catch(error){
        dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")
        setloader(false)
      }
      
console.log(compressedResult)
        },
      });
      
      
      
    }

    const deleteImage=async(id)=>{
      try{
        var res=await deleteUserImage(id, token_access)
        getUserProfileFunc()
      }catch(error){
        dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")
      }
      
    }
    const setMainImage=async(id)=>{
      try{
        var res=await setUserImageMain({image:id}, token_access)
        getUserProfileFunc()
      }catch(error){
        dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")
      }
    }
    const getUserProfileFunc=async()=>{
      try{
        var res1=await getUserProfile(null, token_access)
        successAlert(t("api_success_text"))
        setuser(res1.data)
        setloader(false)
        }catch (error){
        dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")
        setloader(false)
       }
    }

    useEffect(()=>{
      setformattedmaxDate(maxDate())
      setformattedminDate(minDate())
      if(countries===null){
        getCountries()
       }
       if(hobbies===null){
        getHobbies()
       }
       var a=null
       if(user!==null){
        a={...user}
        if(a.hobbies!==null){
             a.hobbies=a.hobbies.map(item=>(item.id))
             sethobby(a.hobbies)
        }
        if(a.birthdate!==null){
          setbithdate(user.birthdate)
        }else{
          setbithdate("")
        }
        setgender(user.gender)
       
        
        if(a.country!==null){
          getState(a.country.id)
          a.country=a.country.id
          setcountry(a.country)
        }
        if(a.state!==null){
          a.state=a.state.id
          setregion(a.state)
        }
       }
       if(filter!==null){
        var b={}
        b.gender_filter=filter.gender
        setgender(filter.gender)
        b.age_from=filter.age_from
        b.age_till=filter.age_till
        if(filter.hobbies!==null){
          b.hobbies_filter=filter.hobbies.map(item=>(item.id))
          sethobby_filter(b.hobbies_filter)
        }
        if(filter.countries!==null){
          b.countries_filter=filter.countries.map(item=>(item.id))
          setcountry_filter(b.countries_filter)
        }
        
        a={...a, ...b}
       }
       form.setFieldsValue(a)
       setloader(false)
    }, [])

    const validatePhone = (rule, value, callback) => {
      const phoneRegex = /[+\d]/g;
      if (value && !phoneRegex.test(value)) {
        callback('Please enter a valid phone address');
      } else {
        callback();
      }
    };
    const getCountries=async()=>{
      
      try{
           var res=await getCountriesHttp(null, token_access)
           setcountries(res.data)
      }catch(error){
           console.log(error)
      }
      
    }
    const getHobbies=async()=>{
      
      try{
           var res=await getHobbiesHttp(null, token_access)
           sethobbies(res.data)
      }catch(error){
           console.log(error)
      }
      
    }
    const getState=async(val)=>{
      setregion(null)
      setloader(true)
      form.setFieldsValue({
        state: null
      });

      try{
           var res=await getStatesHttp({country:val}, token_access)
           
           setstates(res.data)
           setloader(false)
      }catch(error){
           console.log(error)
           setloader(false)
      }
      
    }
    const onFinish=async(val)=>{
      setloader(true)
      var filter_data={}
      val.birthdate=changeDateFormat(val.birthdate)
      filter_data.age_till=val.age_till
      filter_data.age_from=val.age_from
      filter_data.countries=country_filter
      filter_data.hobbies=hobby_filter
      filter_data.gender=val.gender_filter
      val.hobbies=hobby
      try{
        var res=await editUserProfile(val, token_access)
        setuser(res.data)
        var res1=await editUserFilter(filter_data, token_access)
        console.log(res1)
        setfilter(res1.data)
         successAlert(t("success_text"))
         setloader(false)
      }catch (error){
        console.log(error)
        if(error.response.data.detail){
          dangerAlert(t(error.response.data.detail))
        }else{
        dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")}
        setloader(false)
      }
    }
    const deleteHobby=(val)=>{
      var a=hobby
      a.splice(a.indexOf(val), 1)
      sethobby(a)
      setnumber(number+1)
    }
    const addHobby=(val)=>{
      var a=hobby
     a.unshift(val)
      sethobby(a)
      setnumber(number+1)
    }
    const deleteHobbyFilter=(val)=>{
      var a=hobby_filter
      a.splice(a.indexOf(val), 1)
      sethobby_filter(a)
      setnumber(number+1)
    }
    const addHobbyFilter=(val)=>{
      var a=hobby_filter
     a.unshift(val)
      sethobby_filter(a)
      setnumber(number+1)
    }
    const deleteCountryFilter=(val)=>{
      var a=country_filter
      a.splice(a.indexOf(val), 1)
      setcountry_filter(a)
      setnumber(number+1)
    }
    const addCountryFilter=(val)=>{
      var a=country_filter
     a.unshift(val)
      setcountry_filter(a)
      setnumber(number+1)
    }
  return (
    <div className='selfprofile'>
          <Form
    name="form"
    form={form}
   
    labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      onFinish={onFinish}
      onFinishFailed={()=>{dangerAlert("Ma'lumotlaringizni qaytib tekshrib chiqing. Qandaydir kamchilik borga o'xshaydi.")}}
      initialValues={{ remember: false }}
      autoComplete='new-password'
  >
        <div className='self_top'>
            <div className='avatar'>
            <div className='avatar_img' style={{backgroundImage:`url(${user!=null && user.main_image!=null?user.main_image.media:noAvatar(user!==null?user.gender:null)})`}}></div>
          </div>
          <Row className='self_row'>
          <Col lg={24} md={24} sm={24}>
          <h1 className='title_self_profile title_top'>{t("self_info")}</h1>
          </Col>
          <Col lg={12} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label={t("lastname")}
      name="last_name"
      rules={[
        {
          min:2,
          required: true,
          message: t("lastname_error")
        },
      ]}
    >
      <Input className='inputFullName'/>
    </Form.Item>
    <Form.Item
    hasFeedback
      label={t("firstname")}
      name="first_name"
      rules={[
        {
          min:2,
          required: true,
          message: t("firstname_error")
        },
      ]}
    >
      <Input className='inputFullName'/>
    </Form.Item>
    <Form.Item
    hasFeedback
      label={t("gender")}
      name="gender"
      rules={[
        {
         
          required: true,
          message: t("gender_error")
        },
      ]}
    >
      <select value={gender} onChange={(e)=>{setgender(e.target.value)}}>
        {gender===null? <option value={null}>{t("Tanlang")}</option>:<></>}
        <option value={'male'}>{t("male")}</option>
        <option value={'female'}>{t("female")}</option>
        <option value={'rather not say'}>{t("rather not say")}</option>
      </select>
     </Form.Item>
    
<Form.Item
    hasFeedback
      label={t("birthdate")}
     name="birthdate"
      
      rules={[
      
        {
          required: true,
          message: t("birthdate_error"),
         
        },
        
      ]}
    >
   
      <input type='date' className='birthdateinput' format="DD.MM.YYYY" min={formattedminDate} max={formattedmaxDate} />
      {/* <DatePicker onPanelChange={getDateFunc} disabledDate={disabledDate} format="DD.MM.YYYY"/> */}
    </Form.Item>
          </Col>  
          <Col lg={12} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label={t("country")}
      name="country"
      rules={[
        {
          required: true,
          message: t("country_error")
        },
      ]}
    >
    <select  value={country}  onChange={(e)=>{getState(e.target.value); setcountry(e.target.value);}}>
    {country===null? <option value={null}>{t("Tanlang")}</option>:<></>}
       {countries!==null?countries.map((item, key)=>( <option key={key} value={item.id}>{item[`name_${t("error_api")}`]}</option>)):<></>}
    </select>
   
    </Form.Item>
    <Form.Item
    hasFeedback
      label={t("region")}
      name="state"
      
      rules={[
        {
          required: true,
          message: t("region_error")
        },
      ]}
    >
       <select value={region} onChange={(e)=>{setregion(e.target.value)}}>
       {region===null? <option value={null}>{t("Tanlang")}</option>:<></>}
       {states!==null?states.map((item, key)=>( <option key={key} value={item.id}>{item[`name_${t("error_api")}`]}</option>)):<></>}
    </select>
   
    </Form.Item>
    <Form.Item
  hasFeedback
  label={t("phone")}
  name="phone"
  
  rules={[
    {
      pattern: /^(\+\d*)?$/,
      message: t("phone_error_format"),
    },
    {
      required: true,
      message: t("phone_error"),
    },
  ]}
>
  <Input />
</Form.Item>
  
          </Col>
          <Col lg={24} md={24} sm={24}>
          <Form.Item
          hasFeedback
          label={t("comment")}
          name="bio"
         
    >
<Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
    </Form.Item>
          </Col>
          {/* options={hobbies!==null?hobby.length>=3?hobbies.filter(x=>hobby.includes(x.id)).map((item, ket)=>({label:item[`name_${t("error_api")}`],
           value:item.id})):hobbies.map((item, ket)=>({label:item[`name_${t("error_api")}`], value:item.id})):[]} */}
          {hobbies!==null?
          <Col lg={24} md={24} sm={24}>
          <h1 className='title_self_profile'>{t("choose_hobby")}</h1>
            <div  className='mul_select_box'>
              <>
              {hobbies.filter(x=>hobby.includes(x.id)).map((item, ket)=>(
                <div onClick={()=>{deleteHobby(item.id)}} className='mul_select active_sel'>
                     {item[`name_${t("error_api")}`]}
                     <span className='delete_sel_item'><IoIosCloseCircle /></span>
                  </div>
                  ))}
              {hobbies.filter(x=>!hobby.includes(x.id)).map((item, ket)=>(<div  onClick={()=>{if(hobby.length<3){addHobby(item.id)}}} className={`mul_select ${hobby.length>=3?"dis_sel_item":''}`}>
                {item[`name_${t("error_api")}`]}
                <span className='delete_sel_item'><IoIosAddCircle /></span>
             </div>))}
             
             </>
              
            </div>
          </Col>:<></>}

         
          <Col lg={24} md={24} sm={24}>
          <h1 className='title_self_profile'>{t("images")}</h1>
          <div className='avatars_box'>
            {user!=null && user.images!==null?user.images.map((item, key)=>{
              return(
              <div className='avatars_item' key={key}>
              <div className='avatars_img' style={{backgroundImage:`url(${item.media})`}}></div>
              <div className='avatars_btn'>
              <Button onClick={()=>{deleteImage(item.id)}} className='avatar_delete' type="primary" shape="circle" icon={<MdDelete />} size={'middle'} />
              <Switch disabled={user.main_image!=null && item.id===user.main_image.id?true:false} 
              onChange={()=>{setMainImage(item.id)}}
              checked={user.main_image!=null && item.id===user.main_image.id?true:false} />
              </div>
              </div>)
            }):<></>}
            {
              user!=null && (user.images===null || user.images.length<5)?
              <div className='avatars_item'>
                <div className='add_avatar'>
                  <input onChange={uploadImage} accept="image/png, image/jpeg, image/jpg" type='file'/>
                  <div className='avatars_img'>
                   <span>+</span>
                  </div>
                </div>
              </div>:<></>
            }
        </div>
          </Col>
          <Col lg={24} md={24} sm={24}>
            <h1 className='title_self_profile'>{t("find_who")}</h1>
          </Col>
          <Col lg={12} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label={t("age_from")}
      name="age_from"
      rules={[
        {
          type: 'number',
          min: 16,
          max: maxage,
          message: t("age_from_between_error")
        },
        {
          required: true,
          message: t("age_from_error")
        }
      ]}
    >
      <InputNumber onChange={(val)=>{setminage(val)}}/>
    </Form.Item>
          <Form.Item
    hasFeedback
      label={t("gender")}
      name="gender_filter"
      rules={[
        {
         
          required: true,
          message: t("gender_error")
        },
      ]}
    >
      <select value={gender_filter} onChange={(e)=>{setgender_filter(e.target.value)}}>
        {gender_filter===null? <option value={null}>{t("Tanlang")}</option>:<></>}
        <option value={'male'}>{t("male")}</option>
        <option value={'female'}>{t("female")}</option>
        <option value={'rather not say'}>{t("rather not say")}</option>
      </select>
    </Form.Item>
  
    
          </Col>
          <Col lg={12} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label={t("age_till")}
      name="age_till"
      rules={[
        {
          type: 'number',
          min: minage,
          max: 99,
          message: t("age_from_between_error")
        },
        {
          required: true,
          message: t("age_from_error")
        }
      ]}
    >
      <InputNumber  onChange={(val)=>{setmaxage(val)}}/>
    </Form.Item>
          </Col>
  {hobbies!==null?
          <Col lg={24} md={24} sm={24}>
          <h1 className='title_self_profile'>{t("choose_hobby_other")}</h1>
            <div  className='mul_select_box'>
              <>
              {hobbies.filter(x=>hobby_filter.includes(x.id)).map((item, ket)=>(
                <div onClick={()=>{deleteHobbyFilter(item.id)}} className='mul_select active_sel'>
                     {item[`name_${t("error_api")}`]}
                     <span className='delete_sel_item'><IoIosCloseCircle /></span>
                  </div>
                  ))}
              {hobbies.filter(x=>!hobby_filter.includes(x.id)).map((item, ket)=>(<div  onClick={()=>{if(hobby_filter.length<10){addHobbyFilter(item.id)}}} className={`mul_select ${hobby_filter.length>=10?"dis_sel_item":''}`}>
                {item[`name_${t("error_api")}`]}
                <span className='delete_sel_item'><IoIosAddCircle /></span>
             </div>))}
             
             </>
              
            </div>
          </Col>:<></>}
   
          {countries!==null?
          <Col lg={24} md={24} sm={24}>
          <h1 className='title_self_profile'>{t("choose_country_other")}</h1>
            <div  className='mul_select_box'>
              <>
              {countries.filter(x=>country_filter.includes(x.id)).map((item, ket)=>(
                <div onClick={()=>{deleteCountryFilter(item.id)}} className='mul_select active_sel'>
                     {item[`name_${t("error_api")}`]}
                     <span className='delete_sel_item'><IoIosCloseCircle /></span>
                  </div>
                  ))}
              {countries.filter(x=>!country_filter.includes(x.id)).map((item, ket)=>(<div  onClick={()=>{if(country_filter.length<10){addCountryFilter(item.id)}}} className={`mul_select ${country_filter.length>=10?"dis_sel_item":''}`}>
                {item[`name_${t("error_api")}`]}
                <span className='delete_sel_item'><IoIosAddCircle /></span>
             </div>))}
             
             </>
              
            </div>
          </Col>:<></>}
          <div className='btnBox'>
        <Button className='btn' htmlType="submit">
          {t("save")}
        </Button>
        </div>
        
          </Row>
        </div>
        
        
        </Form>
    </div>
  )
}
