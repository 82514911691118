import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { createPassword, registerEmail, sendEmailCodeHttp } from '../host/requests/Auth'
import { checkResponse } from '../host/Host'
import { dangerAlert, successAlert } from '../components/Alert'
import { useStore } from '../store/Store'
import { getUserFilter, getUserProfile } from '../host/requests/User'
import { checkUsersData } from '../components/Checkers'
import { PeopleCards } from '../components/PeopleCards'

export default function Registration() {
  const [form] = Form.useForm();
    const [type, setType]=useState(1)
    const [time, settime]=useState(250)
    const [email, setemail]=useState(null)
    const token_access=useStore(state=>state.token_access)
    const setloader=useStore(state=>state.setloader)
    const setuser=useStore(state=>state.setuser)
    const setfilter=useStore(state=>state.setfilter)
    const settoken_access=useStore(state=>state.settoken_access)
    const settoken_refresh=useStore(state=>state.settoken_refresh)
    const {t}=useTranslation()
    const navigate = useNavigate();
   const emailForm=form;
   const codeForm=form;
   const passwordForm=form;
    const  onFinish=async(val)=>{
     setloader(true)
     try{
        var res=await createPassword(val, token_access)
        passwordForm.resetFields()
        try{
          var res1=await getUserProfile(null, token_access)
          var res2=await getUserFilter(null, token_access)
          if(!checkUsersData(res1.data, res2.data)){
            successAlert(t("fill_user_data"))
          }
          setuser(res1.data)
          
          setloader(false) 
        }catch (error){
            setloader(false)
          dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")
         }
        
        navigate('/')
      }catch (error){
        setloader(false)
        if(error.response.data.detail){
          dangerAlert(t(error.response.data.detail))
        }else{
          dangerAlert(t("error_password"))}
      }
    }

    const getEmailCode=async(val)=>{
     setloader(true)
      setemail(val.email)
      try{
        var res=await registerEmail(val)
        
        successAlert(t("send_code_email"))
        emailForm.resetFields()
        setType(2)
        startTime()
        setloader(false)
      }catch (error){
        if(error.response.data.detail){
          dangerAlert(t(error.response.data.detail))
        }else{
          dangerAlert(t("send_code_email_error"))

        }
        setloader(false)
      }
   }

   const sendEmailCode=async(val)=>{
    setloader(true)
    val.email=email
    try{
      var res=await sendEmailCodeHttp(val)
      console.log(res)
      successAlert(t("send_code_by_email"))
      settoken_access(res.data.access)
      settoken_refresh(res.data.refresh)
      setType(3)
      codeForm.resetFields()
      settime(3)
      setloader(false)
    }catch (error){
      if(error.response.data.detail){
        if(error.response.data.detail==="expired_code"){
          setType(1)
          
          settime(250)
        }
        dangerAlert(t(error.response.data.detail))
      }else{
        dangerAlert(t("send_code_by_email_error"))}
        setloader(false)
    }
 }
 const startTime=()=>{
  var b=setInterval(()=>{
    var history=document.querySelector(".history").innerHTML
    if(history.indexOf("register")===-1){
      clearInterval(b)
    }else{
    if(Number(document.querySelector(".email_time_time").innerHTML)-1<=0){
     clearInterval(b)
     if(Number(document.querySelector(".type").innerHTML)===2){
      goEmail()
     }
    }else{
      settime(Number(document.querySelector(".email_time_time").innerHTML)-1)
    }}
    }, 1000)
 }

 const goEmail=()=>{
  setType(1)
  setemail(null)
  settime(250)
 }
  return (
    <div className='login_big_box'>
        <div className='form_box'>
        <div className='red_box_form'></div>
            <div className='blue_box_form'></div>
           {type===1?
             <div className='login_box'>
            
             <h1>{t("registration")}</h1>
         <Form
     form={emailForm}
     name="emailForm"
     labelCol={{
       span: 24,
     }}
     wrapperCol={{
       span: 24,
     }}
    initialValues={{
       remember: true,
     }}
     onFinish={getEmailCode}
     
     // onFinishFailed={onFinishFailed}
     autoComplete="new-password"
   >
     <Form.Item
       label={t("email")}
       name="email"
       rules={[
         {
           type: 'email',
           message: "",
         },
         {
            required: true,
            message: "",
             
         }
       ]}
     >
       <Input autoComplete="new-password"/>
     </Form.Item>
     <Form.Item
       wrapperCol={{
         span: 24,
       }}
     >
       <Button className='login_btn' type="primary" htmlType="submit">
         {t("send")}
       </Button>
     </Form.Item>
   </Form>
   <div className="social">
           <Link to="/login" className='btns_form'>{t("login")}</Link>
           <Link to="/change-password" className='btns_form'>{t("change-password")}</Link>
         </div>
         </div>
           :type===2?
           <div className='login_box'>
            
           <h1>{t("registration")}</h1>
       <Form
   name="basic1"
   labelCol={{
     span: 24,
   }}
   wrapperCol={{
     span: 24,
   }}
  initialValues={{
     remember: true,
   }}
   onFinish={sendEmailCode}
   form={codeForm}
   // onFinishFailed={onFinishFailed}
   autoComplete="new-password"
 >
  <div className='email_code_div'>
   <Form.Item
      label={t("password_email")}
      name="code"
      rules={[
        {
          len:6,
          message: t("code_email_len"),
        },
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Input autoComplete="new-password"/>
    </Form.Item>
    <div className={`email_time ${time<=10?"red_time":""}`}>
      <span>{parseInt(time/60)<10?"0":""}{parseInt(time/60)}</span>
      <span>:</span>
      <span>{time%60<10?"0":""}{time%60}</span>

    </div>
    </div>
   <Form.Item
     wrapperCol={{
       span: 24,
     }}
   >
     <Button className='login_btn' type="primary" htmlType="submit">
       {t("send")}
     </Button>
   </Form.Item>
 </Form>

       </div>:type===3?
           <div className='login_box'>
            
           <h1>{t("registration")}</h1>
       <Form
   name="basic2"
   labelCol={{
     span: 24,
   }}
   wrapperCol={{
     span: 24,
   }}
  initialValues={{
     remember: true,
   }}
   onFinish={onFinish}
   form={passwordForm}
   // onFinishFailed={onFinishFailed}
   autoComplete="new-password"
 >
   <Form.Item
      label={t("password")}
      name="password"
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Input.Password autoComplete="new-password"/>
    </Form.Item>
    <Form.Item
      label={t("check_password")}
      name="confirm_password"
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Input.Password autoComplete="new-password"/>
    </Form.Item>
   <Form.Item
     wrapperCol={{
       span: 24,
     }}
   >
     <Button className='login_btn' type="primary" htmlType="submit">
       {t("send")}
     </Button>
   </Form.Item>
 </Form>
 
       </div>:<></>
        } 
      
        </div>
        <PeopleCards/>
        
        <div className='email_time_time' style={{display:'none'}}>{time}</div>
        <div className='type' style={{display:'none'}}>{type}</div>

    </div>
  )
}
