import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from '../store/Store'
import { useTranslation } from 'react-i18next'
import { getfaqItem } from '../host/requests/User'

export default function FaqItem() {
  const {id}=useParams()
  const navigate=useNavigate()
  const [data, setData]=useState(null)
  const token_access = useStore(state=>state.token_access)
  const setloader = useStore(state=>state.setloader)
const {t}=useTranslation()
  useEffect(()=>{
    if(!id){
         navigate("/faq")
    }else{
      getData()
    }
  }, [])
  const getData=async()=>{
    setloader(true)
    try{
        var res=await getfaqItem(id, token_access)
        setData(res.data)
     setloader(false)
       }catch (error){
       
       navigate("/faq")
       setloader(false)
      }
}
  return (<>
  {data===null?<div className='emptyList'></div>:<div className='faq_answer'>
        <h1>{data[`question_${t("error_api")}`]}</h1>
         <p className='answer_text' dangerouslySetInnerHTML={{__html:data[`answer_${t("error_api")}`]}} />

    </div>}
  </>
    
  )
}
