import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import './blue.css'
import Home from './pages/Home';
import { useStore } from './store/Store';
import { Route, Routes, useHref, useNavigate } from 'react-router-dom';
import LoginBox from './login_register/LoginBox';
import Loader from './components/Loader';
// import { Helmet } from 'react-helmet-async';

export default function App() {
  const isBlue=useStore(state=>state.isBlue)
  const user=useStore(state=>state.user)
  const loader=useStore(state=>state.loader)
  const setloader=useStore(state=>state.setloader)
  const token_access=useStore(state=>state.token_access)
  const history = useHref();
  const [orientation, setOrientation] = useState(window.orientation);
  const navigate=useNavigate()
  useEffect(()=>{
    
    if(token_access===null && history!=="/login" && history!=="/" && history.indexOf("change-password")===-1  && history.indexOf("register")===-1 ){
      navigate("/login")
    }
     // Function to handle window resize
     const handleResize = () => {
      // Set the body width to 100% on resize
      document.body.style.width = '100%';
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);
    const handleOrientationChange = () => {
      // Prevent the default behavior (rotation) when the orientation changes
      window.addEventListener('orientationchange', (event) => {
        event.preventDefault();
      });
    };

    handleOrientationChange();
    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
 }, [])
  return (
    <div className={isBlue?"blueBox":''} >
      {/* <Helmet>
        <title>Tanishuv platformasi.</title>
        <meta name="description" content="Tanishuv platformasi. O'z juftingizni bizning platforma orqali toping. Ma'lumot xavfsizligi 100% kafolatlanadi." />
        <link rel="icon" type="image/png" href="/static/media/logo.5a3eda131bf42c646251.png" />
      </Helmet> */}
     {loader?<Loader/>:<></>}
      <Routes>
        {user===null?
        <Route path="/*" element={<LoginBox/>} />:
        <Route path="/*" element={<Home/>} />}
      
      </Routes>
      <div className='history' style={{display:'none'}}>{`${history}`}</div>
    </div>
    
  )
}
