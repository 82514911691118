import { Col, Row } from 'antd'
import React from 'react'
import { noAvatar } from '../components/Checkers'
import { useStore } from '../store/Store'
import { AiOutlineLoading } from 'react-icons/ai'
import { TbLock } from 'react-icons/tb'
import { PuffLoader } from 'react-spinners'

export default function ProfileSearch({data, setText, setdata, loader}) {
    const addusersrandom=useStore(state=>state.addusersrandom)
    const user=useStore(state=>state.user)
    const getUserByClick=(a)=>{
       addusersrandom(a)
       setdata([])
       setText("")
    }
    const checkBlockedMe=(a)=>{
        var h=a.filter(x=>x.reaction==='blocked' && x.user===user.id)
        return(h.length!==0)
    }
  return (
    <div className='search_box'>
        {data.length!==0?<Row>
            {data.map((item, key)=>{
                return(
                    <Col className='search_col' xl={4} lg={4} md={8} sm={24}>
                    <div onClick={()=>{getUserByClick(item)}} className='search_card'>
                    {checkBlockedMe(item.reactions)?<div className='block_icon'><TbLock /></div>:<></>}
                    <div className='avatar'>
                    <div className='avatar_img' style={{backgroundImage:`url(${item.main_image!==null?item.main_image:noAvatar(item!==null?item.gender:null)})`}}></div>
                    </div>
                    <p>{item.first_name}</p>
                    </div>
                </Col>
                   
                )
            })}
           
        </Row>:<div className='listBox empty_user'>
           {loader?<div className='loadBtn'><PuffLoader /></div>: <div className='emptyList'></div>}
        </div>}
    </div>
  )
}
