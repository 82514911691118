import React, { useState } from 'react'
import { useStore } from '../store/Store'
import { checkDate, formatDateList, noAvatar } from './Checkers'
import { useTranslation } from 'react-i18next'
import { GoPlus } from "react-icons/go";
import { AiOutlineLoading } from "react-icons/ai";
import { getUserById } from '../host/requests/User';
import { useNavigate } from 'react-router-dom';
import { TbLock } from 'react-icons/tb';
import { PuffLoader } from 'react-spinners';
export default function ChatList({getChats, chat_spin, setchat_spin, setopenMenu, setMenu}) {
  const chats=useStore(state=>state.chats)
  const open_chat=useStore(state=>state.open_chat)
  const token_access=useStore(state=>state.token_access)
  const user=useStore(state=>state.user)
  const chat_next=useStore(state=>state.chat_next)
  const setopen_chat=useStore(state=>state.setopen_chat)
  const setloader=useStore(state=>state.setloader)
  const addusersrandom=useStore(state=>state.addusersrandom)
  const setis_online=useStore(state=>state.setis_online)
  
  
  const navigate=useNavigate()
  const {t} =useTranslation()

  const getUserOne=async(id)=>{
    try{
      setloader(true)
      var res=await getUserById({pk:id}, token_access)
       addusersrandom(res.data)
       setloader(false)
    }catch(error){
      console.log(error)
      setloader(false)
    }
  }
  const createChat=(item)=>{
    if(!(open_chat!==null && item.id===open_chat.id)){
      if(window.screen.availWidth<=800){
        setMenu(4);
      } 
      setloader(true); 
      setis_online(false);
       setopen_chat(item); 
      setopenMenu(false); 
      navigate(user!==null?`/chat/${item.name}`:"")
    }
  
  }
  return (
    <div className='menuList'>
      {[...chats].length===0? <div className='listBox'>
        <div className='emptyList'></div>
    </div>:[...chats].map((item, key)=>{
      if(item.other_user!==null){
        return(<div onClick={()=>{createChat(item)}} key={key} className={`${open_chat!==null && item.id===open_chat.id?"active_chat_item":""} menuListItem`}>
        <div className='user_box'>
         <div className='avatar'>
         <div className='avatar_img' style={{backgroundImage:`url(${item.other_user!==null && item.other_user.main_image!==null?item.other_user.main_image:noAvatar(item!==null?item.other_user.gender:null)})`}}></div>
           
         </div>
         <div className='username'>
              <h1>{item.other_user!==null?item.other_user.first_name:""} {item.blocked_me?<span className='block_icon_menu' style={{color:'red'}}><TbLock/></span>:<></>}</h1>
              <p>{item.is_online?t("online"):formatDateList(item.other_user.last_login, t("error_api"))}</p>
         </div>
       </div>
       {item.unread_messages>0?  <div className='chat_number'>
              {item.unread_messages}
           </div>:<></>}
     
       </div> )
      }
    
    })}
            
      {chat_next!==null && chat_next!=="a"?
       <div onClick={()=>{getChats()}} className='addBtnBox'>
       {chat_spin?<div className='loadBtn'><PuffLoader /></div>: <button><GoPlus/></button>}
      
     </div>
      :<></>}
      
        </div>
  )
}
