import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getUserProfile=async(data, token)=>{
    var res=await httpRequestGet('/profile/', data, token)
    return(res)
}
export const getUserFilter=async(data, token)=>{
    var res=await httpRequestGet('/filters/filter-options/', data, token)
    return(res)
}
export const getUsersDataRandom=async(data, token)=>{
    var res=await httpRequestGet('/filters/users/', data, token)
    return(res)
}
export const getfaqHttp=async(data, token)=>{
    var res=await httpRequestGet('/faq/', data, token)
    return(res)
}
export const getfaqItem=async(data, token)=>{
    var res=await httpRequestGet('/faq/'+data+"/", null, token)
    return(res)
}

export const getUsersDataRandomSearch=async(data, token)=>{
    var res=await httpRequestGet('/search/users/', data, token)
    return(res)
}
export const getUserById=async(data, token)=>{
    var res=await httpRequestGet('/filters/user-detail/', data, token)
    return(res)
}
export const getBlockReasons=async(token)=>{
    var res=await httpRequestGet('/blocking-reasons/without-pagination/', null, token)
    return(res)
}
export const deleteUserImage=async(data, token)=>{
    var res=await httpRequestDelete('/profile/images/', data, token)
    return(res)
}
export const deleteUserBlock=async(data, token)=>{
    var res=await httpRequestDelete('/reactions/', data, token)
    return(res)
}

export const editUserProfile=async(data, token)=>{
    var res=await httpRequestPatch('/profile/', data, token)
    return(res)
}
export const editUserFilter=async(data, token)=>{
    var res=await httpRequestPatch('/filters/filter-options/', data, token)
    return(res)
}
export const addImageToUser=async(data, token)=>{
    var res=await httpRequestPost('/profile/images/', data, token)
    return(res)
}
export const setUserImageMain=async(data, token)=>{
    var res=await httpRequestPost('/profile/set-main-image/', data, token)
    return(res)
}
export const blockUser=async(data, token)=>{
    var res=await httpRequestPost('/my-blocked-users/', data, token)
    return(res)
}
export const deleteblockUser=async(data, token)=>{
    var res=await httpRequestPost('/my-blocked-users/', data, token)
    return(res)
}
export const setLike=async(data, token)=>{
    var res=await httpRequestPost('/my-likes/', data, token)
    return(res)
}

export const getLikeUsers=async(data, token)=>{
    var res=await httpRequestGet('/likes/', data, token)
    return(res)
}
export const getdark_listUsers=async(data, token)=>{
    var res=await httpRequestGet('/my-blocked-users/', data, token)
    return(res)
}
export const getUserForChat=async(data, token)=>{
    var res=await httpRequestPost('/chat/conversations/create/', data, token)
    return(res)
}
export const getChatUsers=async(data, token)=>{
    var res=await httpRequestGet('/chat/conversations/', data, token)
    return(res)
}
export const getChatsHttp=async(data, token)=>{
    var res=await httpRequestGet('/chat/messages/', data, token)
    return(res)
}