import React, { useEffect, useState } from 'react'
import { BsQuestionOctagonFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useStore } from '../store/Store'
import { getfaqHttp } from '../host/requests/User'
import { useTranslation } from 'react-i18next'

export default function Faq() {
  const [data, setData]=useState([])
  const token_access = useStore(state=>state.token_access)
  const setloader = useStore(state=>state.setloader)
const {t}=useTranslation()
 const [page, setpage]=useState(1)
  const [count, setcount]=useState(0)
  useEffect(()=>{
   
      setloader(true)
    
    getData()
},[])
  const getData=async()=>{
    try{
        var res=await getfaqHttp({page:page}, token_access)
        setData(res.data.results)
        setcount(res.data.count)
        setloader(false)
        setpage(page+1)
    }catch (error){
        console.log(error)
        setloader(false)
    }
}
  return (
    <div className='faq_box'>
      {data.length===0 && page===2?
      <div className='emptyList'></div>
      :data.map((item, key)=>{
        return(<Link key={key} to={`${item.id}`} className='faq_item'>
        <span>
          <BsQuestionOctagonFill/>
        </span>
        <p>{item[`question_${t("error_api")}`]}</p>
      </Link>)
      })}
      
     

    </div>
  )
}
