import React from 'react'
import '../css/Loader.css'
import { ClipLoader, GridLoader} from 'react-spinners'
export default function Loader() {
  return (
    <div className='loader_box'> 
     
<GridLoader  size={30} color="#092635" />
    </div>
  )
}
